import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "../../utils/fetchWrapper";
import { BASE_URL } from "../../config";

async function createBucket({ name, arn, createdAt, createdBy }) {
  return await fetchWrapper({
    method: "POST",
    url: `${BASE_URL}/api/data-bucket`,
    body: {
      name,
      arn,
      active: true,
      createdAt,
      createdBy,
    },
  });
}

export function useCreateBucket() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ name, arn, createdAt, createdBy }) =>
      createBucket({ name, arn, createdAt, createdBy }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DataBucket");
      },
      onError: () => {
        console.error("Error: Failed to create the Data Bucket.");
      },
    }
  );
}
