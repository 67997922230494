import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getTaskNodesByJobId(jobId) {
  return fetchWrapper({
    url: `${BASE_URL}/api/job/${jobId}/task`,
    method: "GET",
  });
}

export function useTaskByJobId(jobId) {
  return useQuery(
    ["TaskNodesByJobId", jobId],
    () => getTaskNodesByJobId(jobId),
    {
      onError: () => {
        console.error("Error getting Task Nodes by Job Id");
      },
      enabled: !!jobId,
    }
  );
}
