import React from "react";
import * as Yup from "yup";
import { Loading, Modal, TextInput } from "@carbon/react";
import { Field, Form, Formik } from "formik";
import { useCreateDataResourceDialect } from "../../../hooks/dataresources/useCreateResourceDialect";

const validationSchema = Yup.object().shape({
  displayName: Yup.string().required("This field is required"),
  value: Yup.string().required("This field is required"),
});

function DataResourceDialectAddModal({
  isDataResourceDialectAddModalOpen,
  setIsDataResourceDialectAddModalOpen,
  selectedDataResourceId,
}) {
  const {
    mutateAsync: createDataResourceDialect,
    isLoading: isDialectAddLoading,
  } = useCreateDataResourceDialect(selectedDataResourceId);

  const handleCreateDialects = async (formData) => {
    console.log("handleSubmit");
    const dialectData = {
      dataResourceId: selectedDataResourceId,
      key: formData.displayName,
      value: formData.value,
    };
    await createDataResourceDialect(dialectData);
    setIsDataResourceDialectAddModalOpen(false);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Formik
        initialValues={{ key: "", value: "" }}
        validationSchema={validationSchema}
        onSubmit={(values) => handleCreateDialects(values)}
      >
        {(props) => {
          const { errors, touched } = props;
          return (
            <Modal
              open={isDataResourceDialectAddModalOpen}
              modalHeading="New Resource Dialect"
              primaryButtonText="Add"
              onRequestClose={() => setIsDataResourceDialectAddModalOpen(false)}
              size="sm"
              onRequestSubmit={props.handleSubmit}
              style={{
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 9999,
              }}
              secondaryButtonText="Cancel"
            >
              {isDialectAddLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loading
                    description="Active loading indicator"
                    withOverlay={false}
                  />
                </div>
              ) : (
                <Form>
                  <Field name="displayName">
                    {({ field }) => (
                      <TextInput
                        id="key"
                        labelText="Display Name"
                        invalidText="A valid value is required"
                        size="xl"
                        {...field}
                        invalid={errors.displayName && touched.displayName}
                      />
                    )}
                  </Field>
                  <Field name="value">
                    {({ field }) => (
                      <TextInput
                        id="key"
                        labelText="Value"
                        invalidText="A valid value is required"
                        size="xl"
                        {...field}
                        invalid={errors.value && touched.value}
                      />
                    )}
                  </Field>
                </Form>
              )}
            </Modal>
          );
        }}
      </Formik>
    </div>
  );
}

export default DataResourceDialectAddModal;
