import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "../../utils/fetchWrapper";
import { BASE_URL } from "../../config";

async function deleteDataPackageProperty({ propertyId }) {
  return await fetchWrapper({
    method: "DELETE",
    url: `${BASE_URL}/api/data-package-prop/${propertyId}`,
  });
}

export function useDeleteDataPackageProperty() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ propertyId }) => deleteDataPackageProperty({ propertyId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DataPackageProp");
      },
      onError: () => {
        console.error("Error: Failed to delete the Data Package Property.");
      },
    }
  );
}
