import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getDataByIDResourceProp(resourceId) {
  return fetchWrapper({
    url: `${BASE_URL}/api/data-resource/${resourceId}/prop`,
    method: "GET",
  });
}

export function useDataResourceByIdProperties(resourceId) {
  return useQuery(
    ["DataResourceByIdProperties", resourceId],
    () => getDataByIDResourceProp(resourceId),
    {
      onError: () => {
        console.error("Error getting Data Resource Properties");
      },
      enabled: !!resourceId,
    }
  );
}
