import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getAppLicationData() {
  return fetchWrapper({
    url: `${BASE_URL}/api/application`,
    method: "GET",
  });
}

export function useApplication() {
  return useQuery("Application", getAppLicationData, {
    onError: () => {
      console.error("Error getting Application");
    },
  });
}
