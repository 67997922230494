import React from "react";
import CustomDataTable from "components/Datatable";
import BarChart from "./JobDetailsRunsMatrix";
import { calculateDuration, formatDateTime } from "utils/formatDateTime";
import { useJobRunListByJobId } from "hooks/workflow/useJobRunListByJobId";
import sortJobRunsArray from "utils/sortArrayForJobRuns";

export const JobDetailsRuns = ({ jobId }) => {
  const [isMatrixView, setIsMatrixView] = React.useState(true);
  const {
    data: jobRunListbyJobId = [],
    isLoading: isJobRunListByJobIdLoading,
  } = useJobRunListByJobId(jobId);
  const rowData = React.useMemo(() => {
    const sortedRuns = sortJobRunsArray(jobRunListbyJobId);

    return sortedRuns?.map((data) => ({
      ...data,
      launchType:
        data?.launchType.charAt(0)?.toUpperCase() + data?.launchType.slice(1),
      startedAt: formatDateTime(data?.startedAt),
      duration: calculateDuration(data?.duration),
    }));
  }, [jobRunListbyJobId]);
  const restartHandler = (id) => {
    console.log("starting task again", id);
    // setSingleItemToDeleteID(id);
  };
  const headerData = [
    {
      key: "startedAt",
      header: "Start Time",
    },
    {
      key: "id",
      header: "Job Run ID",
    },
    {
      key: "duration",
      header: "Duration",
    },
    {
      key: "launchType",
      header: "Launched",
    },
    {
      key: "status",
      header: "Status",
    },
  ];

  return (
    <>
      <div>
        <style>
          {`
            .cds--table-toolbar {
                display: none;
            }
            `}
        </style>
        <CustomDataTable
          headers={headerData}
          rows={rowData || []}
          tableHeading="Runs"
          shouldTableBatchActionsRender={false}
          shouldAddNewButton={false}
          buttonText="New Property +"
          deleteAction={restartHandler}
          isActiveTag={false}
          statusWidth="200px"
          isTableLoading={isJobRunListByJobIdLoading}
          inLineContentSwitch={true}
          shouldEditButtonRender={false}
          setIsViewChange={setIsMatrixView}
          shouldOtherViewRender={isMatrixView}
          isSelectionEnable={false}
          shouldDeleteButtonRender={false}
          shouldRenderRestartButton={true}
        />
      </div>
      {!isMatrixView && <BarChart jobId={jobId} />}
    </>
  );
};
