import { BASE_URL } from "config";
import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "utils/fetchWrapper";

async function createDataClassMapping(classMappingList) {
  return await fetchWrapper({
    method: "POST",
    url: `${BASE_URL}/api/data-class-field-mapping`,
    body: classMappingList,
  });
}

export function useCreateDataClassMapping() {
  const queryClient = useQueryClient();

  return useMutation(
    (classMappingList) => createDataClassMapping(classMappingList),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DataClassMapping", [
          "DataClassByLevel",
          2,
        ]);
      },
      onError: () => {
        console.error("Error: Failed to create the Data Class Mapping.");
      },
    }
  );
}
