import { BASE_URL } from "config";
import { useQuery } from "react-query";
import { fetchWrapper } from "utils/fetchWrapper";

export function getDataSummary() {
  return fetchWrapper({
    url: `${BASE_URL}/api/report/data-summary`,
    method: "GET",
  });
}

export function useDataSummary() {
  return useQuery("data-summary", getDataSummary, {
    onError: () => {
      console.error("Error getting Data Summary");
    },
  });
}
