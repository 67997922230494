import { BASE_URL } from "config";
import { useQuery } from "react-query";
import { fetchWrapper } from "utils/fetchWrapper";

export function getTopActiveDataPackages() {
  return fetchWrapper({
    url: `${BASE_URL}/api/report/top-active-data-packages`,
    method: "GET",
  });
}

export function useTopActiveDataPackages() {
  return useQuery("top-active-packages", getTopActiveDataPackages, {
    onError: () => {
      console.error("Error getting Top Active Data Packages");
    },
  });
}
