import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getDataLineageByResourceId(dataPackageId, params) {
  return fetchWrapper({
    url: `${BASE_URL}/api/data-package/${dataPackageId}/lineage?${params}`,
    method: "GET",
  });
}

export function useDataLineageByResourceId(dataPackageId, params) {
  return useQuery(
    ["DataLineageByResourceId", dataPackageId, params],
    () => getDataLineageByResourceId(dataPackageId, params),
    {
      onError: () => {
        console.error("Error getting Data lineage by resource ids");
      },
      enabled: !!dataPackageId && !!params,
    }
  );
}
