import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getJobs() {
  return fetchWrapper({
    url: `${BASE_URL}/api/job`,
    method: "GET",
  });
}

export function useJobs() {
  return useQuery("Jobs", getJobs, {
    onError: () => {
      console.error("Error getting Jobs");
    },
  });
}
