import { useMutation, useQueryClient } from "react-query";
import { BASE_URL } from "../../config";
import { fetchWrapper } from "../../utils/fetchWrapper";

async function deleteDataCollaborator({ collaboratorId }) {
  return await fetchWrapper({
    url: `${BASE_URL}/api/collaborator/${collaboratorId}`,
    method: "DELETE",
  });
}
export function useDeleteDataCollaborator() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ collaboratorId }) => deleteDataCollaborator({ collaboratorId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DataCollaborators");
      },
      onError: () => {
        console.error("Error: Failed Delete the Data Collaborator");
      },
    }
  );
}
