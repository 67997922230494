import React, { useState } from "react";
import {
  AddAlt,
  Edit,
  TrashCan,
  Tools,
  Play,
  Close,
} from "@carbon/react/icons";
import WorkbookChart from "../WorbookTreeChart";
import {
  Button,
  IconButton,
  Column,
  FileUploaderDropContainer,
} from "@carbon/react";
import {
  AFCheckbox,
  AFDropdown,
  AFForm,
  AFRadioButtonGroup,
  AFTextArea,
  AFTextField,
} from "sharedComponents/Form";

const dumyData = [
  {
    id: "1",
    category: "Silver",
    title: "Node Name 1",
    description: "8 columns",
  },
  { id: "2", category: "Gold", title: "Node Name 2", description: "8 columns" },
  {
    id: "3",
    category: "Silver",
    title: "Node Name 3",
    description: "8 columns",
  },
  {
    id: "4",
    category: "Gold",
    title: "Node Name 4",
    description: "8 columns",
  },
  { id: "5", category: "Gold", title: "Node Name 5", description: "8 columns" },
  {
    id: "6",
    category: "Gold",
    title: "Node Name 6",
    description: "3 columns",
  },
  {
    id: "7",
    category: "",
    title: "Node Name 7",
    description: "3 columns",
  },
  {
    id: "8",
    category: "Sandbox",
    title: "Node Name 8",
    description: "3 columns",
  },
  {
    id: "9",
    category: "Sandbox",
    title: "Node Name 9",
    description: "3 columns",
  },
  {
    id: "10",
    category: "Sandbox",
    title: "Node Name 10",
    description: "3 columns",
  },
];

const edgeData = [
  { id: "1", source: "1", target: "2" },
  { id: "2", source: "2", target: "5" },
  { id: "3", source: "2", target: "6" },
  { id: "4", source: "3", target: "4" },
  { id: "5", source: "4", target: "7" },
  { id: "6", source: "5", target: "8" },
  { id: "7", source: "7", target: "9" },
  { id: "8", source: "7", target: "10" },
];

const nodeTypeValues = [
  { id: 1, value: "transformation", labelText: "Transformation" },
  { id: 2, value: "validation", labelText: "Validation" },
];

const typeValues = [
  { id: 1, value: "json", labelText: "JSON Rule" },
  { id: 2, value: "sql", labelText: "SQL" },
  { id: 3, value: "python", labelText: "Python" },
];

const dataLevelType = [
  { id: 1, labelText: "Gold" },
  { id: 2, labelText: "Silver" },
  { id: 3, labelText: "Sandbox" },
];

const dataLevelOptions = dataLevelType.map((data) => ({
  value: data.id,
  label: data.labelText,
}));

const initialValues = {
  node_name: "",
  nodeType: "transformation",
  nodeDescription: "",
  type: "json",
  uploadJson: "",
  dataLevel: "",
  persistState: "",
  uploadPython: "",
};

const WorkbookChartSection = () => {
  const [isSideNavExpanded, setIsSideNavExpanded] = useState(false);
  const [selectedType, setSelectedType] = useState("json");

  const onClickSideNavExpand = (e) => {
    setIsSideNavExpanded(!isSideNavExpanded);
    e.stopPropagation();
  };

  const handleSubmit = (value) => {
    console.log("submitWorked", value);
  };

  const handleValueChange = (value) => {
    setSelectedType(value);
  };

  return (
    <div className="workbook_chart_container">
      <div className="buttons_container">
        <div>
          <Button
            kind="tertiary"
            renderIcon={AddAlt}
            onClick={(event) => onClickSideNavExpand(event)}
          >
            Add New
          </Button>
          <Button kind="tertiary" renderIcon={Edit}>
            Edit
          </Button>
          <Button kind="tertiary" renderIcon={TrashCan}>
            Remove
          </Button>
          <Button kind="tertiary" renderIcon={Tools}>
            Tool
          </Button>
        </div>
        <div>
          <Button renderIcon={Play}>Deliver</Button>
        </div>
      </div>

      {isSideNavExpanded && (
        <>
          <div className="modalBackdrop" onClick={onClickSideNavExpand} />

          <div className="workbookDrawerModal">
            <AFForm initialValues={initialValues} onSubmit={handleSubmit}>
              <Column
                span={16}
                className="flex_between"
                style={{ padding: "10px 0px" }}
              >
                <h3>New Node</h3>
                <IconButton
                  kind="ghost"
                  onClick={onClickSideNavExpand}
                  label="Close"
                >
                  <Close />
                </IconButton>
              </Column>

              <AFTextField name="node_name" label="Node Name" />
              <AFRadioButtonGroup
                name="nodeType"
                label="Node Type"
                options={nodeTypeValues}
                orientation={"horizontal"}
              />
              <AFTextArea
                name="nodeDescription"
                label="Node Description"
                enableCounter={true}
              />
              <AFRadioButtonGroup
                name="type"
                label="Type"
                options={typeValues}
                orientation={"horizontal"}
                handleValueChange={handleValueChange}
              />
              {selectedType === "json" ? (
                <Column span={16} style={{ paddingBottom: "20px" }}>
                  <p className="cds--file--label">Json rule</p>
                  <p className="cds--label-description">
                    Max file size is 500kb. Supported file types are .json
                  </p>
                  <FileUploaderDropContainer
                    style={{
                      outerWidth: "100%",
                    }}
                    labelText="Drag and drop files here or click to upload"
                    multiple={true}
                    accept={["/json"]}
                    disabled={false}
                    name="uploadJson"
                    tabIndex={0}
                  />
                </Column>
              ) : selectedType === "sql" ? (
                <AFTextArea name={"sql"} label={"SQL"} enableCounter={false} />
              ) : (
                <Column span={16} style={{ paddingBottom: "20px" }}>
                  <p className="cds--file--label">Python</p>
                  <p className="cds--label-description">
                    Max file size is 500kb. Supported file types are .py
                  </p>
                  <FileUploaderDropContainer
                    style={{
                      outerWidth: "100%",
                    }}
                    labelText="Drag and drop files here or click to upload"
                    multiple={true}
                    accept={["/py"]}
                    disabled={false}
                    name="uploadPython"
                    tabIndex={0}
                  />
                </Column>
              )}
              <AFDropdown
                label="Data Level"
                name="dataLevel"
                options={dataLevelOptions}
              />
              <AFCheckbox name={"persistState"} label={"Persist State"} />
              <Column span={16} className="flex_between">
                <Button kind="ghost" onClick={onClickSideNavExpand}>
                  Cancel
                </Button>
                <Button type="submit">Create</Button>
              </Column>
            </AFForm>
          </div>
        </>
      )}

      <div>
        <WorkbookChart nodeData={dumyData} edgeData={edgeData} />
      </div>
    </div>
  );
};

export default WorkbookChartSection;
