// src/App.js

import React from "react";
import UIShell from "./content/UIShell/UIShell";
import "./App.scss";

import Loading from "./components/Loading";
import { useAuth0 } from "@auth0/auth0-react";

const App = () => {
  const [sidebarOpen, setSidebarOpen] = React.useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const { isLoading, error } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="app">
      <UIShell
        toggleSidebar={toggleSidebar}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
    </div>
  );
};

export default App;
