export const DATA_MANAGEMENT = {
  dataBucket: "/data-management/data-bucket",
  dataConnectors: "/data-management/data-connectors",
  dataPackage: "/data-management/data-pacakage",
  referenceData: "/data-management/reference-data",
  dataClasses: "/data-management/data-classes",
};

export const WORKFLOW = {
  workflowJob: "/workflow/jobs",
  workflowJobRun: "/workflow/job-runs",
  workflowTask: "workflow/tasks",
};

export const WORK_BENCH = {
  transformation: "/work-bench/transformation",
  dataValidation: "/work-bench/data-validation",
  workbook: "/work-bench/workbook",
};

export const DATA_HEALTH = {
  dataHealthProfile: "/data-health/data-health-profile",
  dataQualityDashboard: "/data-health/data-quality-dashboard",
  dataInventory: "data-health/data-inventory",
  dataLineage: "data-health/data-lineage",
};

export const USER_SETTINGS = {
  userSettings: "/user-settings",
};
