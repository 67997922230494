import { useMutation, useQueryClient } from "react-query";
import { BASE_URL } from "config";
import { fetchWrapper } from "utils/fetchWrapper";

async function deleteDataPackagePotentialName({ potentialNameId }) {
  return await fetchWrapper({
    method: "DELETE",
    url: `${BASE_URL}/api/data-package-potential-table/${potentialNameId}`,
  });
}

export function useDeleteDataPackagePotentialName() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ potentialNameId }) =>
      deleteDataPackagePotentialName({ potentialNameId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DataPackagePotentialName");
      },
      onError: () => {
        console.error(
          "Error: Failed to delete the Data Package Potential Name."
        );
      },
    }
  );
}
