import { BASE_URL } from "config";
import { useQuery } from "react-query";
import { fetchWrapper } from "utils/fetchWrapper";

export function getDataFormat() {
  return fetchWrapper({
    url: `${BASE_URL}/api/data-format`,
    method: "GET",
  });
}

export function useDataFormat() {
  return useQuery("DataFormat", getDataFormat, {
    onError: () => {
      console.error("Error getting Data Format");
    },
  });
}
