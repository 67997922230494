import React from "react";
import * as Yup from "yup";
import {
  AFCheckbox,
  AFDropdown,
  AFFormModal,
  AFNumberInput,
  AFTextField,
} from "sharedComponents/Form";
import { useUpdateRuntime } from "hooks/runtime/useUpdateRuntime";
import RequiredLabel from "sharedComponents/RequiredLabel";
import { clusterSize } from "constants";

function RuntimeEditModal({
  isEditModalOpen,
  setIsEditModalOpen,
  selectedData,
}) {
  const selectedRowData = selectedData;
  const [isManagedByPlatform, setIsManagedByPlatform] = React.useState();
  // isListView?
  // : selectedData?.cells?.reduce((acc, value) => {
  //     const objectKey = value.id.split(":");
  //     return {
  //       ...acc,
  //       [objectKey[1]]: value.value,
  //       id: Number(objectKey[0]),
  //     };
  //   }, {});
  React.useEffect(() => {
    setIsManagedByPlatform(
      selectedData.managedByPlatform === "Yes" ? true : false
    );
  }, [selectedData]);

  const { mutateAsync: updateRuntime, isLoading: isUpdateRuntimeLoading } =
    useUpdateRuntime();
  const runtimeValidationSchema = Yup.object().shape({
    name: Yup.string().required(),
    hostUrl: !isManagedByPlatform && Yup.string().required(),
    clusterSize: isManagedByPlatform && Yup.object().required(),
    idleTimeout:
      isManagedByPlatform &&
      Yup.number()
        .min(0, "idleTimeout should be non-negative number")
        .required(),
  });

  const handleUpdateRuntime = async (formData) => {
    const runtimeValues = {
      state: selectedRowData?.state || "",
      name: formData.name,
      hostUrl: formData.hostUrl,
      cloudProvider: selectedRowData?.cloudProvider || "AWS",
      managedByPlatform: isManagedByPlatform,
      clusterSize: formData.clusterSize?.value,
      idleTimeout: +formData.idleTimeout,
    };
    if (!isManagedByPlatform) {
      delete runtimeValues.clusterSize;
      delete runtimeValues.idleTimeout;
    }
    await updateRuntime({
      runtimeValues: runtimeValues,
      runtimeId: selectedRowData.id,
    });
    setIsEditModalOpen(false);
  };
  const initialValues = {
    name: selectedRowData.name,
    hostUrl: selectedRowData.hostUrl,
    managedByPlatform:
      selectedRowData.managedByPlatform === "Yes" ? true : false,
    idleTimeout: selectedRowData?.idleTimeout || 0,
    clusterSize: clusterSize?.find(
      (data) => data?.label === selectedRowData?.clusterSize
    ),
  };
  return (
    <AFFormModal
      onClose={() => setIsEditModalOpen(false)}
      onSubmit={handleUpdateRuntime}
      validationSchema={runtimeValidationSchema}
      initialValues={initialValues}
      isOpen={isEditModalOpen}
      title="Edit Runtime"
      isLoading={isUpdateRuntimeLoading}
      primaryButtonText="Save"
    >
      <AFTextField name="name" label={<RequiredLabel value="Name" />} />
      <AFCheckbox
        name="managedByPlatform"
        label="Yes"
        legend="Managed by Platform"
        onChange={(e) => setIsManagedByPlatform(e.target.checked)}
      />
      {isManagedByPlatform && (
        <>
          <AFDropdown
            options={clusterSize}
            name="clusterSize"
            label={<RequiredLabel value="EMR Cluster Size" />}
          />
          <AFNumberInput
            name="idleTimeout"
            label={<RequiredLabel value="Idle timeout" />}
          />
        </>
      )}
      {!isManagedByPlatform && (
        <AFTextField
          name="hostUrl"
          label={<RequiredLabel value="Host URL" />}
        />
      )}
    </AFFormModal>
  );
}

export default RuntimeEditModal;
