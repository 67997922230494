import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getJobById(jobId) {
  return fetchWrapper({
    url: `${BASE_URL}/api/job/${jobId}`,
    method: "GET",
  });
}

export function useJobById(jobId) {
  return useQuery(["JobById", jobId], () => getJobById(jobId), {
    onError: () => {
      console.error("Error getting Job by Id");
    },
    enabled: !!jobId,
  });
}
