import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "../../utils/fetchWrapper";
import { BASE_URL } from "../../config";
async function createRuntime({ runtimeValues }) {
  return await fetchWrapper({
    method: "POST",
    url: `${BASE_URL}/api/runtime`,
    body: {
      ...runtimeValues,
    },
  });
}

export function useCreateRuntime() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ runtimeValues }) =>
      createRuntime({
        runtimeValues,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Runtime");
      },
      onError: () => {
        console.error("Error: Failed to create the Runtime.");
      },
    }
  );
}
