import { useMutation, useQueryClient } from "react-query";
import { BASE_URL } from "../../config";
import { fetchWrapper } from "../../utils/fetchWrapper";

async function updateDataConnectionProp({
  key,
  value,
  connectionId,
  connectionPropId,
  isSecret,
}) {
  return await fetchWrapper({
    method: "PUT",
    url: `${BASE_URL}/api/connection-prop/${connectionPropId}`,
    body: {
      key,
      value,
      connectionId,
      isSecret,
    },
  });
}
export function useUpdateDataConnectionProp() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ key, value, connectionId, connectionPropId, isSecret }) =>
      updateDataConnectionProp({
        key,
        value,
        connectionId,
        connectionPropId,
        isSecret,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DataConnectionsProp");
      },
      onError: () => {
        console.error("Error: Failed to update the Data Connection Prop");
      },
    }
  );
}
