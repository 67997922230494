import { BASE_URL } from "config";
import { useQuery } from "react-query";
import { fetchWrapper } from "utils/fetchWrapper";

export function getMostRecentActivity() {
  return fetchWrapper({
    url: `${BASE_URL}/api/data-package/most-recent-data-activity`,
    method: "GET",
  });
}

export function useMostRecentActivity() {
  return useQuery("MostRecentActivity", getMostRecentActivity, {
    onError: () => {
      console.error("Error getting Most Recent Activity");
    },
  });
}
