import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getDataPackageProp() {
  return fetchWrapper({
    url: `${BASE_URL}/api/data-package-prop`,
    method: "GET",
  });
}

export function useDataPackageProp() {
  return useQuery("DataPackageProp", getDataPackageProp, {
    onError: () => {
      console.error("Error getting Data Package Properties");
    },
  });
}
