import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "../../utils/fetchWrapper";
import { BASE_URL } from "../../config";

async function updateDataPackageResource({ resourceData, resourceId }) {
  return await fetchWrapper({
    method: "PUT",
    url: `${BASE_URL}/api/data-resource/${resourceId}`,
    body: resourceData,
  });
}

export function useUpdateDataPackageResourceById() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ resourceData, resourceId }) =>
      updateDataPackageResource({
        resourceData,
        resourceId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DataPackageResourceById");
      },
      onError: () => {
        console.error("Error: Failed to update the Data Package Resource.");
      },
    }
  );
}
