import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "../../utils/fetchWrapper";
import { BASE_URL } from "../../config";

async function updateBucket({
  name,
  arn,
  active,
  createdAt,
  createdBy,
  bucketId,
}) {
  return await fetchWrapper({
    method: "PUT",
    url: `${BASE_URL}/api/data-bucket/${bucketId}`,
    body: {
      id: bucketId,
      name,
      arn,
      active,
      createdAt,
      createdBy: 1,
      userID: 1,
    },
  });
}

export function useUpdateBucket(bucketId) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ name, arn, active, createdAt, createdBy, bucketId }) =>
      updateBucket({ name, arn, active, createdAt, createdBy, bucketId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DataBucket");
      },
      onError: () => {
        console.error("Error: Failed to update the Data Bucket.");
      },
    }
  );
}
