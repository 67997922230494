import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "../../utils/fetchWrapper";
import { BASE_URL } from "../../config";

async function updateDataCollaborator({
  collaboratorId,
  collaboratorRoleId,
  dataPackageId,
  lastActivityAt,
  userId,
}) {
  return await fetchWrapper({
    method: "PUT",
    url: `${BASE_URL}/api/collaborator/${collaboratorId}`,
    body: {
      collaboratorRoleId,
      dataPackageId,
      lastActivityAt,
      userId,
    },
  });
}

export function useUpdateDataCollaborator() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      collaboratorId,
      collaboratorRoleId,
      dataPackageId,
      lastActivityAt,
      userId,
    }) =>
      updateDataCollaborator({
        collaboratorId,
        collaboratorRoleId,
        dataPackageId,
        lastActivityAt,
        userId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DataCollaborators");
      },
      onError: () => {
        console.error("Error: Failed to update the Data Collaborators.");
      },
    }
  );
}
