import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getDataPackageConnections(dataPackageId) {
  return fetchWrapper({
    url: `${BASE_URL}/api/data-package/${dataPackageId}/connection`,
    method: "GET",
  });
}

export function useDataPackageConnections(dataPackageId) {
  return useQuery(
    ["DataPackageConnections", dataPackageId],
    () => getDataPackageConnections(dataPackageId),
    {
      onError: () => {
        console.error("Error getting DataPackage Connections");
      },
      enabled: !!dataPackageId,
    }
  );
}
