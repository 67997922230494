import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "../../utils/fetchWrapper";
import { BASE_URL } from "../../config";

async function updateReferenceData({ referenceData, referenceId }) {
  return await fetchWrapper({
    method: "PUT",
    url: `${BASE_URL}/api/lookup/${referenceId}`,
    body: referenceData,
  });
}

export function useUpdateReferenceData() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ referenceData, referenceId }) =>
      updateReferenceData({
        referenceData,
        referenceId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("ReferenceData");
      },
      onError: () => {
        console.error("Error: Failed to update the Reference Data.");
      },
    }
  );
}
