import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getDataInventoryDetailByPackageId(dataPackageId) {
  return fetchWrapper({
    url: `${BASE_URL}/api/data-package/${dataPackageId}/data-inventory-detail`,
    method: "GET",
  });
}

export function useDataInventoryDetail(dataPackageId) {
  return useQuery(
    ["DataInventoryDetailByDataPackageId", dataPackageId],
    () => getDataInventoryDetailByPackageId(dataPackageId),
    {
      onError: () => {
        console.error("Error getting Data Inventory Details");
      },
      enabled: !!dataPackageId,
      // refetchInterval: 10000,
    }
  );
}
