import { BASE_URL } from "config";
import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "utils/fetchWrapper";

async function createDataClass({ dataClassValues }) {
  return await fetchWrapper({
    method: "POST",
    url: `${BASE_URL}/api/data-class`,
    body: {
      dataClassValues,
    },
  });
}

export function useCreateDataClass() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ dataClassValues }) =>
      createDataClass({
        dataClassValues,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DataClass");
      },
      onError: () => {
        console.error("Error: Failed to create the Data Class.");
      },
    }
  );
}
