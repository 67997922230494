import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "../../utils/fetchWrapper";
import { BASE_URL } from "../../config";
async function updateJob({
  dataPackageId,
  description,
  maxRetries,
  name,
  quartzCronExpression,
  maxConcurrentTasks,
  userId,
  jobId,
  createdAt,
  jobTriggerId,
}) {
  return await fetchWrapper({
    method: "PUT",
    url: `${BASE_URL}/api/job/${jobId}`,
    body: {
      dataPackageId,
      description,
      maxRetries,
      name,
      quartzCronExpression,
      maxConcurrentTasks,
      userId,
      createdAt,
      jobTriggerId,
    },
  });
}

export function useUpdateJob() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      dataPackageId,
      description,
      maxRetries,
      name,
      quartzCronExpression,
      maxConcurrentTasks,
      userId,
      jobId,
      createdAt,
      jobTriggerId,
    }) =>
      updateJob({
        dataPackageId,
        description,
        maxRetries,
        name,
        quartzCronExpression,
        maxConcurrentTasks,
        userId,
        jobId,
        createdAt,
        jobTriggerId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Jobs");
      },
      onError: () => {
        console.error("Error: Failed to update the Jobs.");
      },
    }
  );
}
