import { useMutation } from "react-query";
import { fetchWrapper } from "utils/fetchWrapper";
import { BASE_URL } from "config";

async function autoAssignClass({ dataPackageId }) {
  return await fetchWrapper({
    method: "POST",
    url: `${BASE_URL}/api/data-package/${dataPackageId}/auto-assign-class`,
  });
}

export function useAutoAssignClass() {
  return useMutation(
    ({ dataPackageId }) =>
      autoAssignClass({
        dataPackageId,
      }),
    {
      onSuccess: () => {
        <p>simple message</p>;
      },
      onError: () => {
        console.error("Error: Failed to Auto Assign Class.");
      },
    }
  );
}
