import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getRuntime() {
  return fetchWrapper({
    url: `${BASE_URL}/api/runtime`,
    method: "GET",
  });
}

export function useRuntime() {
  return useQuery("Runtime", getRuntime, {
    onError: () => {
      console.error("Error getting Runtime");
    },
  });
}
