import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "../../utils/fetchWrapper";
import { BASE_URL } from "../../config";

async function deleteDataPackage({ dataPackageId }) {
  return await fetchWrapper({
    method: "DELETE",
    url: `${BASE_URL}/api/data-package/${dataPackageId}`,
  });
}

export function useDeleteDataPackage() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ dataPackageId }) => deleteDataPackage({ dataPackageId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DataPackage");
      },
      onError: () => {
        console.error("Error: Failed to delete the Data Package.");
      },
    }
  );
}
