import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getDataPackageResourceById(dataPackageId) {
  return fetchWrapper({
    url: `${BASE_URL}/api/data-package/${dataPackageId}/data-resource`,
    method: "GET",
  });
}

export function useDataPackageResourceById(dataPackageId) {
  return useQuery(
    ["DataPackageResourceById", dataPackageId],
    () => getDataPackageResourceById(dataPackageId),
    {
      onError: () => {
        console.error("Error getting Data Package Resource");
      },
      enabled: !!dataPackageId,
    }
  );
}
