import { BASE_URL } from "config";
import { useQuery } from "react-query";
import { fetchWrapper } from "utils/fetchWrapper";

export function getDataClassMapping() {
  return fetchWrapper({
    url: `${BASE_URL}/api/data-class-field-mapping`,
    method: "GET",
  });
}

export function useDataClassMapping() {
  return useQuery("DataClassMapping", getDataClassMapping, {
    onError: () => {
      console.error("Error getting Data Class mapping");
    },
  });
}
