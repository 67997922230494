import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getDataClassbyLevel(level) {
  return fetchWrapper({
    url: `${BASE_URL}/api/data-class?level=${level}`,
    method: "GET",
  });
}

export function useDataClassbyLevel(level) {
  return useQuery(
    ["DataClassByLevel", level],
    () => getDataClassbyLevel(level),
    {
      onError: () => {
        console.error("Error getting Data Class by Level");
      },
    }
  );
}
