import { useMutation, useQueryClient } from "react-query";
import { BASE_URL } from "../../config";
import { fetchWrapper } from "../../utils/fetchWrapper";

async function deleteResourceDialect({ dialectId }) {
  return await fetchWrapper({
    url: `${BASE_URL}/api/data-resource-dialect/${dialectId}`,
    method: "DELETE",
  });
}
export function useDeleteResourceDialect(dataResourceId) {
  const queryClient = useQueryClient();
  return useMutation(({ dialectId }) => deleteResourceDialect({ dialectId }), {
    onSuccess: () => {
      queryClient.invalidateQueries([
        "DataResourceByIdDialect",
        dataResourceId,
      ]);
    },
    onError: () => {
      console.error("Error: Failed Delete the Data Resource Dialect");
    },
  });
}
