import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "../../utils/fetchWrapper";
import { BASE_URL } from "../../config";
async function udpateTask({
  applicationId,
  jobId,
  name,
  parentId,
  runtimeId,
  source,
  taskTypeId,
  taskId,
  dependsOn,
}) {
  return await fetchWrapper({
    method: "PUT",
    url: `${BASE_URL}/api/task/${taskId}`,
    body: {
      applicationId,
      jobId,
      name,
      parentId,
      runtimeId,
      source,
      taskTypeId,
      dependsOn,
    },
  });
}

export function useUpdateTask() {
  const queryClient = useQueryClient();

  return useMutation(
    ({
      applicationId,
      jobId,
      name,
      parentId,
      runtimeId,
      source,
      taskTypeId,
      taskId,
      dependsOn,
    }) =>
      udpateTask({
        applicationId,
        jobId,
        name,
        parentId,
        runtimeId,
        source,
        taskTypeId,
        taskId,
        dependsOn,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("TaskNodesByJobId");
      },
      onError: () => {
        console.error("Error: Failed to update the Task Nodes.");
      },
    }
  );
}
