import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "../../utils/fetchWrapper";
import { BASE_URL } from "../../config";

async function createResourceSchema({
  hasLookAhead,
  version,
  lookahead,
  dataResourceId,
  tableNameX,
}) {
  return await fetchWrapper({
    method: "POST",
    url: `${BASE_URL}/api/data-resource/${dataResourceId}/resource-schema`,
    body: {
      hasLookAhead,
      version,
      lookahead,
      dataResourceId,
      tableNameX,
    },
  });
}

export function useCreateResourceSchema(dataResourceId) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ hasLookAhead, version, lookahead, dataResourceId, tableNameX }) =>
      createResourceSchema({
        hasLookAhead,
        version,
        lookahead,
        dataResourceId,
        tableNameX,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["DataResourceSchema", dataResourceId]);
      },
      onError: () => {
        console.error("Error: Failed to create the Data Resource Schema.");
      },
    }
  );
}
