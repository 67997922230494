import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "../../utils/fetchWrapper";
import { BASE_URL } from "../../config";

async function createApplication({ name, path, className, applicationType }) {
  return await fetchWrapper({
    method: "POST",
    url: `${BASE_URL}/api/application`,
    body: {
      name,
      path,
      className,
      applicationType,
    },
  });
}

export function useCreateApplication() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ name, path, className, applicationType }) =>
      createApplication({
        name,
        path,
        className,
        applicationType,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("Application");
      },
      onError: () => {
        console.error("Error: Failed to create the Application.");
      },
    }
  );
}
