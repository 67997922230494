import React from "react";
import WorkbookChartSection from "./WorkbookChartSection/WorkbookChart";
import WorkbookTabs from "./WorkbookTable/WorkbookTabs";
import "./workbook.scss";

const Workbook = () => {
  return (
    <div className="workbook_main_container">
      <h3>Workbooks</h3>
      <WorkbookChartSection />
      <WorkbookTabs />
    </div>
  );
};

export default Workbook;
