import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "../../utils/fetchWrapper";
import { BASE_URL } from "../../config";

async function updateResourceDialectsById({
  key,
  value,
  dataResourceId,
  dialectId,
}) {
  return await fetchWrapper({
    method: "PUT",
    url: `${BASE_URL}/api/data-resource-dialect/${dialectId}`,
    body: {
      key,
      value,
      dataResourceId,
    },
  });
}

export function useUpdateDataResourceDialectById(dataResourceId, dialectId) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ key, value, dataResourceId }) =>
      updateResourceDialectsById({
        key,
        value,
        dataResourceId,
        dialectId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "DataResourceByIdDialect",
          dataResourceId,
        ]);
      },
      onError: () => {
        console.error("Error: Failed to create the Data Resource Dialects.");
      },
    }
  );
}
