import React, { useState, useRef, useEffect } from "react";
import { Canvas, Edge, Node, Port } from "reaflow";
import TreeNode from "./TreeNode";
import {
  Edit,
  Copy,
  Paste,
  Replicate,
  PaintBrush,
  ViewOff,
} from "@carbon/react/icons";

function TreeChart({ nodes, edges }) {
  const [selectedNode, setSelectedNode] = useState(null);
  const [dropdownPosition, setDropdownPosition] = useState(null);
  const containerRef = useRef(null);

  const ports = nodes.map((node) => {
    const hasOutgoingEdge = edges.some((edge) => edge.from === node.id);
    const hasIncomingEdge = edges.some((edge) => edge.to === node.id);

    const nodePorts = [];
    if (hasOutgoingEdge) {
      nodePorts.push({
        id: `${node.id}-from`,
        width: 10,
        height: 10,
        side: "EAST",
      });
    }
    if (hasIncomingEdge) {
      nodePorts.push({
        id: `${node.id}-to`,
        width: 10,
        height: 10,
        side: "WEST",
      });
    }

    return {
      ...node,
      ports: nodePorts,
    };
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setSelectedNode(null);
        setDropdownPosition(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const updatedEdges = edges.map((edge) => ({
    ...edge,
    fromPort: `${edge.from}-from`,
    toPort: `${edge.to}-to`,
  }));

  const activeNodeHandling = (event, id) => {
    if (selectedNode === id) {
      setSelectedNode(null);
      setDropdownPosition(null);
    } else {
      setSelectedNode(id);
    }

    const element = event.currentTarget;
    const rect = element.getBoundingClientRect();

    setDropdownPosition({
      top: rect.top + window.scrollY - rect.height / 2,
      left: rect.left + window.scrollX - rect.width / 2,
    });
  };

  return (
    <div style={{ position: "relative" }} ref={containerRef}>
      {selectedNode && (
        <div
          className="dropdown"
          style={{
            position: "absolute",
            top: `${dropdownPosition.top}px`,
            left: `${dropdownPosition.left}px`,
            transform: "translate(-50%, -50%)",
          }}
        >
          <ul>
            <li>
              <span>
                <Edit />
              </span>
              Edit
            </li>
            <li>
              <span>
                <Copy />
              </span>
              Copy
            </li>
            <hr />
            <li>
              <span>
                <Paste />
              </span>
              Paste
            </li>
            <hr />
            <li>
              <span>
                <Replicate />
              </span>
              Duplicate
            </li>
            <hr />
            <li>
              <span>
                <PaintBrush />
              </span>
              color nodes
            </li>
            <hr />
            <li>
              <span>
                <ViewOff />
              </span>
              Hide this node
            </li>
          </ul>
        </div>
      )}
      <style>
        {`
          .edge {
            stroke: gray;
            animation: dashdraw .5s linear infinite;
            stroke-width: 2;
          }
         
          `}
      </style>
      <Canvas
        className="workbook-chart-canvas"
        nodes={ports}
        edges={updatedEdges}
        edge={<Edge className="edge" />}
        arrow={null}
        direction="Right"
        maxHeight={550}
        maxWidth={1400}
        onCanvasClick={(event) => {
          setSelectedNode(null);
        }}
        node={
          <Node
            onClick={(event, node) => {
              activeNodeHandling(event, node.id);
            }}
            port={
              <Port
                style={{
                  fill: "gray",
                  stroke: "none",
                }}
                rx={10}
                ry={10}
              />
            }
          >
            {(event) => {
              return <TreeNode selectedNode={selectedNode} event={event} />;
            }}
          </Node>
        }
      />
    </div>
  );
}

export default TreeChart;
