export function transformedDate(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const formattedDate = day + "-" + month + "-" + year;
  if (formattedDate === "NaN-NaN-NaN") {
    return dateString;
  } else return formattedDate;
}
