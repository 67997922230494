import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getDataCollaboratorRoles() {
  return fetchWrapper({
    url: `${BASE_URL}/api/collaborator-role`,
    method: "GET",
  });
}

export function useDataCollaboratorRoles() {
  return useQuery("DataCollaboratorsRoles", getDataCollaboratorRoles, {
    onError: () => {
      console.error("Error getting Data Collaborators Roles");
    },
  });
}
