import { useQuery } from "react-query";
import { BASE_URL } from "../../config";
import { fetchWrapper } from "../../utils/fetchWrapper";

async function getConnectionType() {
  return fetchWrapper({
    url: `${BASE_URL}/api/connection-type`,
  });
}

export function useConnectionType() {
  return useQuery("ConnectionTypes", getConnectionType, {
    onError: () => {
      console.error("Error: Failed to get Connection Types");
    },
  });
}
