import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getDataConnectionProps() {
  return fetchWrapper({
    url: `${BASE_URL}/api/connection-prop`,
    method: "GET",
  });
}

export function useDataConnectionProps() {
  return useQuery("DataConnectionsProp", getDataConnectionProps, {
    onError: () => {
      console.error("Error getting Data Connections Properties");
    },
  });
}
