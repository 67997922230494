import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "../../utils/fetchWrapper";
import { BASE_URL } from "../../config";

async function createDataResourcePropById({
  key,
  value,
  appendToRecord,
  dataResourceId,
  propertyID,
}) {
  return await fetchWrapper({
    method: "PUT",
    url: `${BASE_URL}/api/data-resource-prop/${propertyID}`,
    body: {
      key,
      value,
      appendToRecord,
      dataResourceId,
    },
  });
}

export function useUpdateDataResourcePropById(dataResourceId, propertyID) {
  const queryClient = useQueryClient();

  return useMutation(
    ({ key, value, appendToRecord, dataResourceId }) =>
      createDataResourcePropById({
        key,
        value,
        appendToRecord,
        dataResourceId,
        propertyID,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "DataResourceByIdProperties",
          dataResourceId,
        ]);
      },
      onError: () => {
        console.error("Error: Failed to create the Data Resource Properties.");
      },
    }
  );
}
