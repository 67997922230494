import React from "react";
import { Tab, Tabs, TabList, TabPanel, TabPanels } from "@carbon/react";
import WorkbookLeftColumn from "./WorkbookLeftColumn";
import CustomDataTable from "components/Datatable";

const columnData = [
  {
    columnName: "id",
    id: "1",
    work_book_id: "WB001",
    rule_type: "1",
    depends_on: "[null]",
    name: "Transformation",
    description: "rule checks...",
    dataType: "Integar",
  },
  {
    columnName: "work_book_id",
    id: "2",
    work_book_id: "WB002",
    rule_type: "1",
    depends_on: "1,2,3,4,5",
    name: "Transformation",
    description: "rule checks...",
    dataType: "Integar",
  },
  {
    columnName: "rule_type",
    id: "3",
    work_book_id: "WB003",
    rule_type: "1",
    depends_on: "1,2,3,4,5",
    name: "Transformation",
    description: "rule checks...",
    dataType: "Integar",
  },
  {
    columnName: "depends_on",
    id: "4",
    work_book_id: "WB004",
    rule_type: "1",
    depends_on: "[null]",
    name: "Transformation",
    description: "rule checks...",
    dataType: "String",
  },
  {
    columnName: "name",
    id: "5",
    work_book_id: "WB005",
    rule_type: "1",
    depends_on: "[null]",
    name: "Transformation",
    description: "rule checks...",
    dataType: "String",
  },
];

const ErrorHeaderData = [
  {
    key: "target",
    header: "Target",
  },
  {
    key: "Reported",
    header: "Reported",
  },
  {
    key: "failure_reason",
    header: "Failure reason",
    type: "integar",
  },
];

const headerData = [
  {
    key: "id",
    header: "id",
    type: "integar",
  },
  {
    key: "work_book_id",
    header: "work_book_id",
    type: "integar",
  },
  {
    key: "rule_type",
    header: "rule_type",
    type: "integar",
  },
  {
    key: "depends_on",
    header: "depends_on",
    type: "character varying (80)",
  },
  {
    key: "name",
    header: "name",
    type: "character varying (150)",
  },
  {
    key: "description",
    header: "description",
    type: "character varying (255)",
  },
  {
    key: "saved_data",
    header: "saved_data",
    type: "boolean",
  },
  {
    key: "rule_data",
    header: "rule_data",
    type: "json",
  },
  {
    key: "data_level_type_id",
    header: "data_level_type_id",
    type: "integar",
  },
  {
    key: "data_class_id",
    header: "data_class_id",
    type: "integar",
  },
  {
    key: "created_at",
    header: "created_at",
    type: "timestamp without timezone",
  },
  {
    key: "updated_at",
    header: "updated_at",
    type: "timestamp without timezone",
  },
];

const WorkbookTabs = () => {
  return (
    <div>
      <Tabs>
        <TabList aria-label="List of tabs">
          <Tab>Preview</Tab>
          <Tab>Run History</Tab>
          <Tab>Error</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <div style={{ display: "flex" }}>
              <style>
                {`.noheader .cds--data-table-header {
                          display:none;
                          padding:0;
                          }
                          .noheader .cds--table-toolbar {
                            min-height: 0;
                        }`}
              </style>
              <div>
                <WorkbookLeftColumn columnData={columnData} />
              </div>

              <div className="noheader" style={{ overflowX: "auto" }}>
                <CustomDataTable
                  headers={headerData}
                  rows={columnData}
                  shouldTableBatchActionsRender={false}
                  isSelectionEnable={false}
                  shouldAddNewButton={false}
                  isActiveTag={false}
                  statusWidth="200px"
                  tableInlineSearch={false}
                  shouldActionsRender={false}
                  showDownloadButton={false}
                  isSortable={true}
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="noheader" style={{ overflowX: "auto" }}>
              <CustomDataTable
                headers={headerData}
                rows={columnData}
                shouldTableBatchActionsRender={false}
                isSelectionEnable={false}
                shouldAddNewButton={false}
                isActiveTag={false}
                statusWidth="200px"
                tableInlineSearch={false}
                shouldActionsRender={false}
                showDownloadButton={false}
                isSortable={true}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div
              className="noheader"
              style={{ overflowX: "auto", width: "100%" }}
            >
              <CustomDataTable
                headers={ErrorHeaderData}
                rows={columnData}
                shouldTableBatchActionsRender={false}
                isSelectionEnable={false}
                shouldAddNewButton={false}
                isActiveTag={false}
                statusWidth="200px"
                tableInlineSearch={false}
                shouldActionsRender={false}
                showDownloadButton={false}
                isSortable={true}
              />
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default WorkbookTabs;
