import { useMutation, useQueryClient } from "react-query";
import { BASE_URL } from "../../config";
import { fetchWrapper } from "../../utils/fetchWrapper";

async function deleteResourceSchema({ schemaId, dataResourceId }) {
  return await fetchWrapper({
    url: `${BASE_URL}/api/data-resource/${dataResourceId}/resource-schema/${schemaId}`,
    method: "DELETE",
  });
}
export function useDeleteResourceSchema(dataResourceId) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ schemaId, dataResourceId }) =>
      deleteResourceSchema({ schemaId, dataResourceId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["DataResourceSchema", dataResourceId]);
      },
      onError: () => {
        console.error("Error: Failed Delete the Data Resource Schema");
      },
    }
  );
}
