import React from "react";
import DataMatchingTileContent from "./DataMatchingTileContent";

function DataMatchingModalDefineMatching({ selectedTile }) {
  return (
    <>
      <DataMatchingTileContent selectedTile={selectedTile} />
    </>
  );
}

export default DataMatchingModalDefineMatching;
