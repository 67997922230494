import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getProfileDetailById(profileKey) {
  return fetchWrapper({
    url: `${BASE_URL}/api/profile-header/${profileKey}/details`,
    method: "GET",
  });
}

export function useProfileDetailById(profileKey) {
  return useQuery(
    ["DataProfileHeaderByID", profileKey],
    () => getProfileDetailById(profileKey),
    {
      onError: () => {
        console.error("Error getting Profile Details");
      },
      enabled: !!profileKey,
    }
  );
}
