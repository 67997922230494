import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "../../utils/fetchWrapper";
import { BASE_URL } from "../../config";

async function deleteDataConnectionProp({ connectionPropId }) {
  return await fetchWrapper({
    method: "DELETE",
    url: `${BASE_URL}/api/connection-prop/${connectionPropId}`,
  });
}

export function useDeleteDataConnectionProp() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ connectionPropId }) => deleteDataConnectionProp({ connectionPropId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DataConnectionsProp");
      },
      onError: () => {
        console.error("Error: Failed to delete the Data Connection Prop.");
      },
    }
  );
}
