import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getDataPackageActivity(dataPackageId) {
  return fetchWrapper({
    url: `${BASE_URL}/api/data-package/${dataPackageId}/activity`,
    method: "GET",
  });
}

export function useDataPackageActivitiesById(dataPackageId) {
  return useQuery(
    ["DataPackageActivity", dataPackageId],
    () => getDataPackageActivity(dataPackageId),
    {
      onError: () => {
        console.error("Error getting DataPackage Activity");
      },
      enabled: !!dataPackageId,
    }
  );
}
