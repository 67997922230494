import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getDataQualityByResourceId(resourceId) {
  return fetchWrapper({
    url: `${BASE_URL}/api/data-resource/${resourceId}/data-quality`,
    method: "GET",
  });
}

export function useDataQualityByResourceId(resourceId) {
  return useQuery(
    ["DataQualityByResourceId", resourceId],
    () => getDataQualityByResourceId(resourceId),
    {
      onError: () => {
        console.error("Error getting Data Quality");
      },
      enabled: !!resourceId,
    }
  );
}
