import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getDataBucket() {
  return fetchWrapper({
    url: `${BASE_URL}/api/data-bucket`,
    method: "GET",
  });
}

export function useDataBucket() {
  return useQuery("DataBucket", getDataBucket, {
    onError: () => {
      console.error("Error getting Data Bucket");
    },
  });
}
