import { useMutation, useQueryClient } from "react-query";
import { BASE_URL } from "../../config";
import { fetchWrapper } from "../../utils/fetchWrapper";

async function updateRuntimeProp({ key, value, runtimeId, id }) {
  return await fetchWrapper({
    method: "PUT",
    url: `${BASE_URL}/api/runtime-prop/${id}`,
    body: {
      key,
      value,
      runtimeId,
    },
  });
}
export function useUpdateRuntimeProp() {
  const queryClient = useQueryClient();
  return useMutation(
    ({ key, value, runtimeId, id }) =>
      updateRuntimeProp({
        key,
        value,
        runtimeId,
        id,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("RuntimeProps");
      },
      onError: () => {
        console.error("Error: Failed to update the Runtime Prop");
      },
    }
  );
}
