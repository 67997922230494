import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "../../utils/fetchWrapper";
import { BASE_URL } from "../../config";

async function updateeDataPackageProperties({
  key,
  value,
  appendToRecord,
  dataPackageId,
  id,
}) {
  return await fetchWrapper({
    method: "PUT",
    url: `${BASE_URL}/api/data-package-prop/${id}`,
    body: {
      dataPackageId,
      key,
      value,
      appendToRecord,
    },
  });
}

export function useUpdateDataPackageProperties() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, key, value, appendToRecord, dataPackageId }) =>
      updateeDataPackageProperties({
        id,
        key,
        value,
        appendToRecord,
        dataPackageId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DataPackageProp");
      },
      onError: () => {
        console.error("Error: Failed to update the Data Package Properties.");
      },
    }
  );
}
