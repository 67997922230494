import { BASE_URL } from "config";
import { useQuery } from "react-query";
import { fetchWrapper } from "utils/fetchWrapper";

export function taskNotification() {
  return fetchWrapper({
    url: `${BASE_URL}/api/task-notification`,
    method: "GET",
  });
}

export function useTaskNotification() {
  return useQuery("TaskNotifications", taskNotification, {
    onError: () => {
      console.error("Error getting Task Notifications");
    },
  });
}
