import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getDataLineage(dataPackageId) {
  return fetchWrapper({
    url: `${BASE_URL}/api/data-package/${dataPackageId}/lineage`,
    method: "GET",
  });
}

export function useDataLineage(dataPackageId) {
  return useQuery(
    ["DataLineage", dataPackageId],
    () => getDataLineage(dataPackageId),
    {
      onError: () => {
        console.error("Error getting Data lineage");
      },
      enabled: !!dataPackageId,
    }
  );
}
