import { BASE_URL } from "config";
import { useQuery } from "react-query";
import { fetchWrapper } from "utils/fetchWrapper";

export function getDataInventoryStatus() {
  return fetchWrapper({
    url: `${BASE_URL}/api/report/inventory-status`,
    method: "GET",
  });
}

export function useDataInventoryStatus() {
  return useQuery("data-inventory-status", getDataInventoryStatus, {
    onError: () => {
      console.error("Error getting Data Inventory Status");
    },
  });
}
