import { BASE_URL } from "config";
import { useQuery } from "react-query";
import { fetchWrapper } from "utils/fetchWrapper";

export function getDataPackagePotentialTableName() {
  return fetchWrapper({
    url: `${BASE_URL}/api/data-package-potential-table`,
    method: "GET",
  });
}

export function useDataPackagePotentialTableName() {
  return useQuery(
    "DataPackagePotentialName",
    getDataPackagePotentialTableName,
    {
      onError: () => {
        console.error("Error getting Data Package Potential Table Name");
      },
    }
  );
}
