import { useMutation, useQueryClient } from "react-query";
import { BASE_URL } from "../../config";
import { fetchWrapper } from "../../utils/fetchWrapper";

async function deleteDataResourcePropety({ propertyId }) {
  return await fetchWrapper({
    url: `${BASE_URL}/api/data-resource-prop/${propertyId}`,
    method: "DELETE",
  });
}
export function useDeleteDataResourcePropety(dataResourceId) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ propertyId }) => deleteDataResourcePropety({ propertyId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "DataResourceByIdProperties",
          dataResourceId,
        ]);
      },
      onError: () => {
        console.error("Error: Failed Delete the Data Resource Property");
      },
    }
  );
}
