import { BASE_URL } from "config";
import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "utils/fetchWrapper";

async function updateDataClassMapping({
  mappingId,
  dataClassId,
  dataResourceId,
  fieldName,
}) {
  return await fetchWrapper({
    method: "PUT",
    url: `${BASE_URL}/api/data-class-field-mapping/${mappingId}`,
    body: {
      dataClassId,
      dataResourceId,
      fieldName,
    },
  });
}

export function useUpdateDataClassMapping() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ dataClassId, dataResourceId, fieldName, mappingId }) =>
      updateDataClassMapping({
        dataClassId,
        dataResourceId,
        fieldName,
        mappingId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("DataClassMapping", [
          "DataClassByLevel",
          2,
        ]);
      },
      onError: () => {
        console.error("Error: Failed to update the Data Class Mapping.");
      },
    }
  );
}
