import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getProfileDataTypeById(profileKey) {
  return fetchWrapper({
    url: `${BASE_URL}/api/profile-detail/${profileKey}/data-type`,
    method: "GET",
  });
}

export function useProfileDataTypeById(profileKey) {
  return useQuery(
    ["DataProfileDataTypeByID", profileKey],
    () => getProfileDataTypeById(profileKey),
    {
      onError: () => {
        console.error("Error getting Profile Data Type");
      },
      enabled: !!profileKey,
    }
  );
}
