import { useMutation, useQueryClient } from "react-query";
import { BASE_URL } from "../../config";
import { fetchWrapper } from "../../utils/fetchWrapper";

async function deleteJob({ jobId }) {
  return await fetchWrapper({
    url: `${BASE_URL}/api/job/${jobId}`,
    method: "DELETE",
  });
}
export function useDeleteJob() {
  const queryClient = useQueryClient();
  return useMutation(({ jobId }) => deleteJob({ jobId }), {
    onSuccess: () => {
      queryClient.invalidateQueries("Jobs");
    },
    onError: () => {
      console.error("Error: Failed Delete the Job");
    },
  });
}
