import { useMutation, useQueryClient } from "react-query";
import { fetchWrapper } from "utils/fetchWrapper";
import { BASE_URL } from "config";

async function autoGenerateRules({ dataPackageId }) {
  return await fetchWrapper({
    method: "POST",
    url: `${BASE_URL}/api/data-package/${dataPackageId}/auto-generate-rules`,
  });
}

export function useAutoGenerateRules() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ dataPackageId }) =>
      autoGenerateRules({
        dataPackageId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("");
      },
      onError: () => {
        console.error("Error: Failed to Auto Generate Rules.");
      },
    }
  );
}
