import React from "react";
import * as Yup from "yup";
import {
  AFCheckbox,
  AFDropdown,
  AFFormModal,
  AFNumberInput,
  AFTextField,
} from "sharedComponents/Form";
import { useCreateRuntime } from "hooks/runtime/useCreateRuntime";
import RequiredLabel from "sharedComponents/RequiredLabel";
import { clusterSize } from "constants";

function RuntimeAddModal({ isOpen, setIsOpen }) {
  const [isManagedByPlatform, setIsManagedByPlatform] = React.useState(false);

  const runtimeValidationSchema = Yup.object().shape({
    name: Yup.string().required(),
    hostUrl: !isManagedByPlatform && Yup.string().required(),
    clusterSize: isManagedByPlatform && Yup.object().required(),
    idleTimeout:
      isManagedByPlatform &&
      Yup.number()
        .min(0, "idleTimeout should be non-negative number")
        .required(),
  });

  const { mutateAsync: createRuntime, isLoading: isCreateRuntimeLoading } =
    useCreateRuntime();
  const handleCreateRuntime = async (formData) => {
    const date = new Date().toISOString();
    const newDate = date.split("T").join(" ").split(".");

    const runtimeValues = {
      startTime: newDate[0],
      cloudProvider: "AWS",
      state: "terminated",
      name: formData.name,
      hostUrl: formData.hostUrl,
      managedByPlatform: isManagedByPlatform,
      clusterSize: formData.clusterSize.value,
      idleTimeout: +formData.idleTimeout,
    };
    if (isManagedByPlatform) {
      delete runtimeValues.hostUrl;
    } else {
      delete runtimeValues.clusterSize;
      delete runtimeValues.idleTimeout;
    }
    await createRuntime({ runtimeValues: runtimeValues });
    setIsOpen(false);
  };
  const initialValues = {
    name: "",
    hostUrl: "",
    managedByPlatform: false,
    idleTimeout: 0,
    clusterSize: "",
  };
  return (
    <>
      <AFFormModal
        onClose={() => setIsOpen(false)}
        onSubmit={handleCreateRuntime}
        validationSchema={runtimeValidationSchema}
        initialValues={initialValues}
        isOpen={isOpen}
        title="New Runtime"
        isLoading={isCreateRuntimeLoading}
        primaryButtonText="Create"
      >
        <AFTextField name="name" label={<RequiredLabel value="Name" />} />
        <AFCheckbox
          name="managedByPlatform"
          label="Yes"
          legend="Managed by Platform"
          onChange={(e) => setIsManagedByPlatform(e.target.checked)}
        />
        {isManagedByPlatform && (
          <>
            <AFDropdown
              options={clusterSize}
              name="clusterSize"
              label={<RequiredLabel value="EMR Cluster Size" />}
            />
            <AFNumberInput
              name="idleTimeout"
              label={<RequiredLabel value="Idle timeout" />}
            />
          </>
        )}
        {!isManagedByPlatform && (
          <AFTextField
            name="hostUrl"
            label={<RequiredLabel value="Host URL" />}
          />
        )}
      </AFFormModal>
    </>
  );
}

export default RuntimeAddModal;
