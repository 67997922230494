import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getProfileCorrelationsById(profileKey) {
  return fetchWrapper({
    url: `${BASE_URL}/api/profile-header/${profileKey}/correlations`,
    method: "GET",
  });
}

export function useProfileCorrelationsById(profileKey) {
  return useQuery(
    ["DataProfileCorrelationsByID", profileKey],
    () => getProfileCorrelationsById(profileKey),
    {
      onError: () => {
        console.error("Error getting Profile Correlations");
      },
      enabled: !!profileKey,
    }
  );
}
