import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getDataResourceActivity(dataResourceId) {
  return fetchWrapper({
    url: `${BASE_URL}/api/data-resource/${dataResourceId}/activity`,
    method: "GET",
  });
}

export function useDataResourceActivitiesById(dataResourceId) {
  return useQuery(
    ["DataResourceActivity", dataResourceId],
    () => getDataResourceActivity(dataResourceId),
    {
      onError: () => {
        console.error("Error getting Data Resource Activity");
      },
      enabled: !!dataResourceId,
    }
  );
}
