import { fetchWrapper } from "../../utils/fetchWrapper";
import { useQuery } from "react-query";
import { BASE_URL } from "../../config";

export function getResourceSchema(resourceId) {
  return fetchWrapper({
    url: `${BASE_URL}/api/data-resource/${resourceId}/resource-schema`,
    method: "GET",
  });
}

export function useResourceSchema(resourceId) {
  return useQuery(
    ["DataResourceSchema", resourceId],
    () => getResourceSchema(resourceId),
    {
      onError: () => {
        console.error("Error getting Data Resource Schema");
      },
      enabled: !!resourceId,
    }
  );
}
